export enum OpenpriseErrorCodes {

  OPENPRISE_PROXY_REQUEST_CONFIG_ERROR = 'OPENPRISE_PROXY_REQUEST_CONFIG_ERROR',

  // proxy error codes
  OPENPRISE_PROXY_UNKNOWN_ERROR = 'OPENPRISE_PROXY_UNKNOWN_ERROR',
  OPENPRISE_SERVICE_REQUEST_CONFIG_ERROR = 'OPENPRISE_SERVICE_REQUEST_CONFIG_ERROR',
  OPENPRISE_SERVICE_SERVICE_CONFIG_ERROR = 'OPENPRISE_SERVICE_SERVICE_CONFIG_ERROR',

  // openprise error codes
  OPENPRISE_SERVICE_UNKNOWN_ERROR = 'OPENPRISE_SERVICE_UNKNOWN_ERROR',

  // snowflake proxy error codes
  OPENPRISE_SNOWFLAKE_PROXY_ERROR = 'OPENPRISE_SNOWFLAKE_PROXY_ERROR'
}

export enum OpenpriseErrorMessages {

  // proxy error messages
  OpenpriseProxyRequestConfigError = 'Request body to www-vercel-openprise proxy not properly formatted as non-empty object.',
  OpenpriseProxyUnknownError = 'Unknown www-vercel-openprise proxy error. It could be network-related.',
  OpenpriseServiceEmptyResponse = 'Openprise returned an empty response.',

  // openprise error messages
  OpenpriseServiceRequestConfigError = 'Invalid request posted to Openprise.',
  OpenpriseServiceUnconfiguredFields = 'Fields not configured in Openprise.',
  OpenpriseServiceUnknownError = 'Unknown Openprise error. It could be network-related.',

  // snowflake proxy error messages
  OpenpriseSnowflakeProxyError = 'The Snowflake proxy has not properly recorded the response'
}

export enum OpenpriseErrorSeverity {
  Error = 'error', // Submission failure
  Warning = 'warning' // Caught validation error, submission sent
}

export type OpenpriseErrorBody = {
  contextualData?: Record<string, unknown>;
  correlationId: string;
  errorCode: OpenpriseErrorCodes;
  message: string;
  severity: OpenpriseErrorSeverity;
  statusCode: number;
};

export class OpenpriseError extends Error {
  public severity: OpenpriseErrorSeverity;

  public contextualData: Record<string, unknown>;

  public errorCode: OpenpriseErrorCodes;

  public statusCode: number;

  public correlationId: string;

  constructor({
    contextualData,
    correlationId,
    errorCode,
    message,
    severity,
    statusCode,
  }: OpenpriseErrorBody) {
    super(message);

    this.contextualData = contextualData || {};
    this.contextualData.stack = this.contextualData.stack || this.stack;
    this.message = message;
    this.severity = severity;
    this.errorCode = errorCode;
    this.statusCode = statusCode;
    this.correlationId = correlationId;
  }
}
