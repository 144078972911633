import { FormType, OpenpriseFieldKey } from '@rippling/utils';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { redirect } from 'next/navigation';
import Cookies from 'universal-cookie';

import { postToOpenpriseProxy } from ':lib/openprise';
import {
  getOptimizelyEventKeys,
  handleOptimizelyGTMTracking,
  postToOptimizelyProxy
} from ':lib/optimizely';

import { get30Days, getXHoursFromNow } from './date-helpers';

export const capterraTrackers = {
  Australia: ['2300254', '9e3a062ef763eb52ac9807a9004c126b'],
  Canada: ['2300252', 'c68623610552a6dd44ddcd7cd6ce764b'],
  France: ['2300935', 'ed46e90587e8dc86b46d3da2e380198d'],
  NetherlandsBelgium: ['2330553', '40afef419c0b5d980d432cc14ba54a0a'],
  UK: ['2249605', 'd6b52d8415f4856d863b576454387b5b'],
  US: ['2119234', '5d388caf8dac5a4cabc7669880b61e59'],
} as const;

export const googleConversion = {
  google_conversion_color: 'ffffff',
  google_conversion_format: '3',
  google_conversion_id: 794_594_069,
  google_conversion_label: 'odM0CJb_sIkBEJWW8voC',
  google_conversion_language: 'en',
  google_remarketing_only: false,
} as const;

export const formDataToObject = (formData: FormData) => {
  const object = {};
  for (const [key, value] of formData.entries()) {
    object[key] = value;
  }

  return object;
};

export const getDemoDateTime = (
  data: {
    schedule_demo_date: string;
    schedule_demo_time: string;
    schedule_demo_timezone: string;
  },
  opts: { locale?: string } = {}
) => {
  const { locale } = opts;
  const {
    schedule_demo_date: demoDate,
    schedule_demo_time: demoTime,
    schedule_demo_timezone: demoTimezone,
  } = data;

  if (!demoDate) {
    return '';
  }

  // Construct string to be parsed
  const dateTimeString = `${demoDate} ${demoTime}`;
  const dateObj = parse(dateTimeString, 'MM/dd/yyyy HH:mm', new Date());

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Invalid_date
  // Fallback plan to construct the string
  if (Number.isNaN(dateObj.getDate())) {
    const [_hours, minutes] = demoTime.split(':');
    let hours = Number.parseInt(_hours, 10);
    let meridiem = 'am';

    if (hours >= 12) {
      meridiem = 'pm';

      if (hours > 12) {
        hours = hours - 12;
      }
    }

    return `${demoDate} @ ${hours}:${minutes}${meridiem} ${demoTimezone}`;
  }

  const allLocalesData =
    '__localeData__' in window ? window.__localeData__ : {};
  const localeData = allLocalesData[locale];

  return `${format(
    dateObj,

    // This should be the same format as what's being shown on DatePicker
    'PP @ p',
    localeData && { locale: localeData }
  )} ${demoTimezone ?? ''}`.trim();
};

export const getGASessionId = (cookies: Cookies) => {
  const value = cookies.get('_ga_5FVLVKX2DN') ?? ''; // _ga_<container_id>
  const pattern = /GS\d\.\d\.(.+?)(?:;|$)/;
  const match = value.match(pattern);
  let sessionId = null;

  if (match && match[1]) {
    sessionId = match[1].split('.')[0];
  }

  return sessionId || '';
};

// From cookies
const getOPValuesFromCookies = (cookies: Cookies) => {
  // Mapping from cookie key to Openprise field
  const cookieToOpenpriseMapping = {
    _ga: 'GA4_Client_ID__c',
    aclid: 'MVF_Click_ID__c',
    fbclid: 'FBCLID__c',
    gclid: 'GCLID__c',
    initial_landing_page: 'Landing_Page__c',
    msclkid: 'MSCLKID__c',
    previous_url: 'Form_Fill_Referring_URL__c',
    utm_campaign: 'LT_Campaign__c',
    utm_content: 'LT_Content__c',
    utm_group: 'UTM Group',
    utm_medium: 'LT_Medium__c',
    utm_program: 'UTM Program',
    utm_refacc: 'UTM ReferralAccount',
    utm_source: 'LT_Source__c',
    utm_term: 'LT_Term__c',
  };

  // Just the list of cookie keys
  const cookieKeys = Object.keys(cookieToOpenpriseMapping);

  const allCookies = cookies.getAll();
  const validCookies = Object.keys(allCookies).filter((key) =>
    cookieKeys.includes(key));

  // Additional logic (parsing, etc)
  const referCookieInfo = cookies.get('rippling_refer_user');

  return validCookies.reduce(
    (previous, current) => ({
      ...previous,
      [cookieToOpenpriseMapping[current]]: cookies.get(current),
    }),
    {
      ga_session_id: getGASessionId(cookies),
      GetAmbassador__Short_Code__c: referCookieInfo?.short_code ?? '',
    }
  );
};

// From other places
const getOPValues = (dataLayer) => {
  // Clearbit
  const cbReveal = dataLayer.find((item) => item?.reveal)?.reveal ?? {};

  return {
    referrer_url__c: window.document.referrer ?? '',
    user_agent: window.navigator.userAgent,
    visitor_ip: cbReveal.ip ?? '',
  };
};

const getOptimizelyExperimentsFromCookies = (cookies: Cookies) => {
  let experimentCookies = '';
  const allCookies = cookies.getAll();

  for (const [key] of Object.entries(allCookies)) {
    const experiment = key.match(/opt_(.*)_user/)?.[1];

    if (experiment) {
      const variation = cookies.get(`opt_${experiment}_variation`);

      experimentCookies += `${experiment}:${variation},`;
    }
  }

  return experimentCookies;
};

// Trigger capterra tracking
const firePPCSitePixels = () => {
  for (const [vid, vkey] of Object.values(capterraTrackers)) {
    (function() {
      const ct = document.createElement('script');
      ct.type = 'text/javascript';
      ct.async = true;
      ct.src = `//ct.capterra.com/capterra_tracker.js?vid=${vid}&vkey=${vkey}`;
      const s = document.querySelectorAll('script')[0];
      s.parentNode.insertBefore(ct, s);
    })();
  }
};

// Trigger crozdesk tracking
const fireCrozdeskTracking = () => {
  (function() {
    const cdx = document.createElement('script');
    cdx.type = 'text/javascript';
    cdx.async = true;
    cdx.src = '//trk.crozdesk.com/FC4ZLTASMywvbywebrSQ';
    const s = document.querySelectorAll('script')[0];
    s.parentNode.insertBefore(cdx, s);
  })();
};

const googleAdwordsTracking = () => {
  if (!window.google_trackConversion) {
    return;
  }

  window.google_trackConversion(googleConversion);
};

const handleOptimizelyTracking = ({
  cookies,
  formType,
}: {
  cookies: Cookies;
  formType: FormType;
}): Record<string, string> => {
  const eventKeys = getOptimizelyEventKeys(formType);
  const optimizelyFormData = handleOptimizelyGTMTracking(eventKeys);

  return Object.entries(optimizelyFormData).reduce(
    (acc, [key, val]) => {
      if (key) {
        acc[key] = val.join(', ');
      }

      return acc;
    },
    { Optimizely_Experiment_ID__c: getOptimizelyExperimentsFromCookies(cookies) }
  );
};

const postSubmit = async ({
  cookies,
  formData,
  formType,
}: {
  cookies: Cookies;
  formData: Record<OpenpriseFieldKey, string>;
  formType: FormType;
}) => {
  const { hostname } = window.location;

  // Optimizely
  const eventKeys = getOptimizelyEventKeys(formType);

  // Fire and forget
  await postToOptimizelyProxy(eventKeys);

  // GTM
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'Form Submit (Success)',
      formCompanyName: formData.Company,
      formFullName: formData.FullName,
      formLeadSource: formData.ReferrerSource,
      formNumberOfEmployees: formData.NumberOfEmployees,
      formType: formData.Form_Type__c,
      formWorkEmail: formData.Email,
      formWorkPhone: formData.Phone,

      // sheduleDemoDate: formData.schedule_demo_date,
    });
  }

  // Clearbit
  try {
    if (window.clearbit?.identify) {
      window.clearbit.identify(formData.Email, { email: formData.Email });
    }
  } catch (error) {
    console.log('e:', error);
  }

  // Set cookies
  cookies.set('last_form_submit', Date.now(), { expires: getXHoursFromNow(0.0833) });

  cookies.set('thankyou_email', formData.Email, {
    domain: hostname,
    expires: get30Days(),
    path: '/',
  });

  cookies.set('saved_form_values', formData, {
    domain: hostname,
    expires: getXHoursFromNow(48),
    path: '/',
  });
};

export const submitForm = async ({
  formData,
  formType,
  thankYouUrl,
}: {
  formData: Record<OpenpriseFieldKey, string>;
  formType: FormType;
  thankYouUrl: string;
}) => {
  const cookies = new Cookies();
  const dataLayer = window.dataLayer ?? [];

  // Fire trackings
  googleAdwordsTracking();
  fireCrozdeskTracking();
  firePPCSitePixels();

  // Construct the body data
  const bodyData = {
    ...formData,
    ...getOPValuesFromCookies(cookies),
    ...getOPValues(dataLayer),
    ...handleOptimizelyTracking({
      cookies,
      formType,
    }),
  };

  try {
    // Submit to Openprise
    await postToOpenpriseProxy({
      formData: bodyData,
      lastFormSubmit: cookies.get('last_form_submit'),
    });

    // Post submission
    await postSubmit({
      cookies,
      formData: bodyData,
      formType,
    });

    if (thankYouUrl) {
      redirect(thankYouUrl);
    }
  } catch {
    // Log something here
  }
};
