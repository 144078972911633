import { postRequestToJSONAPI } from '@rippling/utils/networkUtils';
import {
  isNonEmptyArray,
  isNonEmptyObject
} from '@rippling/utils/validationUtils';

import {
  OPENPRISE_API_KEY,
  OPENPRISE_ENDPOINT,
  OPENPRISE_POD_NAME
} from ':constants/env';
import { log, VercelLogMessage } from ':lib/vercel';

import {
  OpenpriseError,
  OpenpriseErrorCodes,
  OpenpriseErrorMessages,
  OpenpriseErrorSeverity
} from './errors/openprise-error';

export const CORRELATION_ID_HEADER = 'x-rippling-cid';

export const WWW_SERVICE_NAMESPACE = 'rippling-www';

export type FieldValidation = {
  expected: string;
  fieldName: string;
};

const isOpenpriseErrorResponse = (json: {
  error_code: number;
  error_message: string;
  incorrect_attributes: Record<string, string>;
  success: boolean;
}) => {
  return Boolean(!json.success ||
      json.error_code ||
      json.error_message ||
      json.incorrect_attributes);
};

/*
Fetch handlers
*/

// Function for vercel server to push form submission to Openprise. On success, it
// will return the fields that were accepted by Openprise. This can be used to
// determine which fields weren't accepted, which can be reported.
export const postToOpenprise = async (
  formData: Record<string, unknown>,
  correlationId: string
) => {
  try {
    const headers = {
      apiKey: OPENPRISE_API_KEY,
      [CORRELATION_ID_HEADER]: correlationId,
      podName: OPENPRISE_POD_NAME,
    };

    const response = await postRequestToJSONAPI({
      body: JSON.stringify([formData]),
      headers,
      url: OPENPRISE_ENDPOINT,
    });

    // catch any hard errors
    if (isOpenpriseErrorResponse(response)) {
      const {
        data,
        error_code: errorCode,
        error_message: errorMessage,
        incorrect_attributes: incorrectAttributes = {},
      } = response;

      throw new OpenpriseError({
        contextualData: {
          acceptedFields: Object.keys(data?.[0] || {}), // sometimes we'll get empty data ([])
          errorCode,
          errorMessage,
          incorrectAttributes, // OP validation errors
        },
        correlationId,
        errorCode: OpenpriseErrorCodes.OPENPRISE_SERVICE_REQUEST_CONFIG_ERROR,
        message: OpenpriseErrorMessages.OpenpriseServiceRequestConfigError,
        severity: OpenpriseErrorSeverity.Error,
        statusCode: 400,
      });
    }

    const acceptedFormData = response.data?.[0] || null;

    // catch any soft config errors
    if (isNonEmptyObject(acceptedFormData)) {
      // [possible success] We compare the returned form data vs submitted form data
      const unconfiguredFields = getUnconfiguredFields(
        formData,
        acceptedFormData
      );

      if (isNonEmptyArray(unconfiguredFields)) {
        const error = new OpenpriseError({
          contextualData: { unconfiguredFields: unconfiguredFields },
          correlationId,
          errorCode: OpenpriseErrorCodes.OPENPRISE_SERVICE_SERVICE_CONFIG_ERROR,
          message: OpenpriseErrorMessages.OpenpriseServiceUnconfiguredFields,
          severity: OpenpriseErrorSeverity.Warning,
          statusCode: 200,
        });

        logOpenpriseErrorMessage(error);
      }
    } else {
      // [possible failure] if returned form data is empty for some reason... (its happened before...)
      const warningError = new OpenpriseError({
        contextualData: { responseData: response.data },
        correlationId,
        errorCode: OpenpriseErrorCodes.OPENPRISE_SERVICE_SERVICE_CONFIG_ERROR,
        message: OpenpriseErrorMessages.OpenpriseServiceEmptyResponse,
        severity: OpenpriseErrorSeverity.Warning,
        statusCode: 200,
      });

      logOpenpriseErrorMessage(warningError);
    }

    logOpenpriseMessage({
      message: `Form submitted - ${correlationId}`,
      type: 'info',
    });

    return acceptedFormData;
  } catch (error) {
    const errorToUse =
      error instanceof OpenpriseError
        ? error
        : new OpenpriseError({
          correlationId,
          errorCode: OpenpriseErrorCodes.OPENPRISE_SERVICE_UNKNOWN_ERROR,
          message: error.message,
          severity: OpenpriseErrorSeverity.Error,
          statusCode: 500,
        });

    logOpenpriseErrorMessage(errorToUse);
  }
};

// Function used in the form submission handler on the client to the vercel server endpoint,
// which is used to proxy the request to Openprise
export const postToOpenpriseProxy = async (formData: Record<string, unknown>) => {
  return fetch('/api/www-vercel-openprise', {
    body: JSON.stringify(formData),
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
  });
};

/*
Post-form submission
*/

// after submission, get the fields that Openprise didn't accept
export const getUnconfiguredFields = (
  submittedFormData: Record<string, unknown>,
  acceptedFormData: Record<string, unknown>
): string[] => {
  const fieldsSubmittedNames = Object.keys(submittedFormData);
  const fieldsAcceptedNames = Object.keys(acceptedFormData);

  return fieldsSubmittedNames.filter((value) => !fieldsAcceptedNames.includes(value));
};

/**
 * Logging
 */
export const logOpenpriseErrorMessage = (error: OpenpriseError) => {
  const {
    contextualData, correlationId, errorCode, message, severity,
  } = error;

  logOpenpriseMessage({
    data: {
      contextualData,
      correlationId,
      errorCode,
    },
    message,
    type: severity === OpenpriseErrorSeverity.Error ? 'error' : 'warn',
  });
};

export const logOpenpriseMessage = (message: Omit<VercelLogMessage, 'apiName'>) => {
  log({
    apiName: 'www-vercel-openprise',
    ...message,
  });
};

// Remove this mapping and "getFormDataWithCampaignName" once Marketo and p_name is deprecated
export const pNameToCampaignNameMap = {
  '2023-06-10 SHRM Conference': '2023-06-10 SHRM Conference',
  'contact-us': 'WF-Web Form-Contact Us',
  'content-lp': 'WF-Web Form-Content Webinars LP',
  'lp-home': 'WF-Web Form-Rippling Request Demo',
  'Partner Event 2022-06-08 AICPA Happy Hour':
    'Partner Event 2022-06-08 AICPA Happy Hour',
  peo: 'WF-Web Form-PEO Demo Request',
  peopleops: 'FB-Facebook Instapage',
  'product-tour': 'WF-Web Form-Product Tour',
  'quiz-program': 'WF-PEO Quiz Trigger Program',
  'request-demo': 'WF-Web Form-Rippling Request Demo',
  'request-quote': 'WF-Web Form-Rippling Request Quote',
  'shell-program': 'WF-xxDemo Request Shell Program',
  'simple-form': 'WF-Web Form-Rippling Demo Drop Off',
  subscribe: 'Product Update Subscribe',
  'ur-program': 'Beta and Research Subscribe',
  'video-tour': 'WF-Web Form-Product Tour',
  'WF-xxDemo Request Qualifying Data': 'WF-xxDemo Request Qualifying Data',
} as const;

export const getCampaignNameFromPName = (formData: Record<string, unknown>): string => {
  if (formData.video_tour) {
    return pNameToCampaignNameMap['video-tour'];
  }

  if (formData.request_quote) {
    return pNameToCampaignNameMap['request-quote'];
  }

  if (formData.p_name) {
    const pName = (formData.p_name as string) ?? '';

    return (
      pNameToCampaignNameMap[pName] ||
      pNameToCampaignNameMap['request-demo']
    );
  }

  return pNameToCampaignNameMap['request-demo'];
};

export const getFormDataWithCampaignName = (formData: Record<string, unknown>): Record<string, unknown> => {
  if (formData.campaign_name) {
    return formData;
  }

  const resultFormData = { ...formData };
  resultFormData.campaign_name = getCampaignNameFromPName(resultFormData);

  return resultFormData;
};
